import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator } from '@angular/material';
import { Project } from 'src/model/project';
import { SelectionModel } from '@angular/cdk/collections';
import { AngularFireDatabase } from '@angular/fire/database';
import { User } from 'src/model/user';
import * as firebase from 'firebase/app';

export interface AssignData {
  type: string;
  key: string;
  param: string;
  keys: any[];
  name: string;
}

@Component({
  selector: 'app-assign-dlg',
  templateUrl: './assign-dlg.component.html',
  styleUrls: ['./assign-dlg.component.scss']
})
export class AssignDlgComponent implements OnInit {
  type;
  title;
  table = {
    display: 'none'
  };
  projects = [];
  users = [];
  keys = [];
  mostrarBotones:boolean = true;
  nombreUser:string = '';
  noData;
  displayedColumns;
  dataSource;
  selection;
  loading = false;
  projectKey;
  param;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor(
    public dialogRef: MatDialogRef<AssignData>,
    @Inject(MAT_DIALOG_DATA) public data: AssignData,
    public db: AngularFireDatabase,
  ) {
    this.type = this.data.type;
    console.log(this.type)
    if (this.type === 'project') {
      this.param = this.data.param;
      if (!this.param) {
        this.projectKey = this.data.key;
      }
      this.title = 'Asignar Usuario al Proyecto.';
      this.displayedColumns = ['select', 'photo', 'name', 'email', 'phone', 'role'];
      this.dataSource = new MatTableDataSource<User>();
      this.selection = new SelectionModel<User>(true, []);
    }
    else if (this.type === 'myProyects') {
      this.param = this.data.param;
      if (!this.param) { this.keys = this.data.keys;  this.nombreUser = this.data.name; }
      this.mostrarBotones = false;
      this.title = 'Proyectos asignados a '+this.nombreUser;
      this.displayedColumns = ['photo', 'title', 'desc', 'status'];
      this.dataSource = new MatTableDataSource<Project>();
      this.selection = new SelectionModel<Project>(true, []);
    }
    else {
      this.title = 'Asignar Proyecto al Usuario.';
      this.displayedColumns = ['select', 'photo', 'title', 'desc', 'status'];
      this.dataSource = new MatTableDataSource<Project>();
      this.selection = new SelectionModel<Project>(true, []);
    }
   }

  ngOnInit() {
    this.loading = true;
    if (this.type === 'user') {
      this.db.list('/projects').valueChanges().subscribe((result: any) => {
        //console.log('projects ====>', result);
        this.projects = result;
        if (this.projects.length > 0) {
          this.noData = false;
          this.dataSource = new MatTableDataSource(this.projects);
          this.dataSource.paginator = this.paginator;
          this.table = {
            display: 'block'
          };
        } else {
          this.noData = true;
          this.table = {
            display: 'none'
          };
        }
        this.loading = false;
      });
    }
    else if (this.type === 'myProyects') {
      this.db.list('/projects').valueChanges().subscribe((result: any) => {
        this.projects = result;
        if (this.projects.length > 0) {
          this.noData = false;
          let projectFilter = new Array();
          this.projects.forEach(item =>  {
            let mostrar = false;
              this.keys.forEach(proj =>{
                if(proj === item.project_key){ mostrar = true; }
              })
              if(mostrar){projectFilter.push(item); console.log(item)}
          });
          this.dataSource = new MatTableDataSource(projectFilter);
          this.dataSource.paginator = this.paginator;
          this.table = { display: 'block' };
        } else {
          this.noData = true;
          this.table = {
            display: 'none'
          };
        }
        this.loading = false;
      });
    }
    else {
      this.db.list('/users').valueChanges().subscribe((result: any) => {
        //console.log('users ====>', result);
        this.users = result;
        if (this.users.length > 0) {
          this.noData = false;
          let UsersFilter = new Array();
          this.users.forEach(item =>  {
            let mostrar = false;
            if(typeof item.assigned_projects !== 'undefined'){
              let arrays = Object.values(item.assigned_projects)
              arrays.forEach(proj =>{
                if(proj === this.projectKey){ mostrar = true; }
              })
              if(!mostrar){UsersFilter.push(item);}
            }
            else{ UsersFilter.push(item); }
          });
          this.dataSource = new MatTableDataSource(UsersFilter);
          this.dataSource.paginator = this.paginator;
          this.table = {
            display: 'block'
          };
        } else {
          this.noData = true;
          this.table = {
            display: 'none'
          };
        }
        this.loading = false;
      });
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${this.type === 'user' ? row.project_key + 1 : row.uid + 1}`;
  }

  cancel() {
    this.dialogRef.close();
  }

  assign() {
    if (this.selection.selected.length > 0) {
      if (this.type === 'user') {
        const projects = [];
        this.selection.selected.forEach(project => {
          projects.push(project.project_key);
        });
        this.dialogRef.close({data: projects});
      } else {
        if(this.param === 'New') {
          let users = [];
          this.selection.selected.map(user => {
            users.push(user);
          });
          this.dialogRef.close({users: users});
        } else {
          Promise.all(this.selection.selected.map(user => {
            firebase.database().ref().child('users/' + user.uid).child('assigned_projects').push(this.projectKey);
            /*if (user.assigned_projects === undefined) {
                firebase.database().ref().child('users/' + user.uid).child('assigned_projects').set([this.projectKey]);
            } else {
              const assignProjects = [...new Set([...user.assigned_projects, ...[this.projectKey]])];
              console.log('new projects===>', assignProjects);
              firebase.database().ref().child('users/' + user.uid).child('assigned_projects').set(assignProjects);
            }*/
          }));
          this.dialogRef.close();
        }
      }
    }
  }
}
