export class User {
  uid?: string;
  name?: string;
  email?: string;
  password?: string;
  phone?: string;
  photo?: string = null;
  role?: string;
  company?: string;
  assigned_projects?: any[];
}
