import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from 'src/layout/full/full.component';
import { BlankComponent } from 'src/layout/blank/blank.component';
import { AuthGuardService } from '../service/auth-guard.service';
import { HistorialComponent } from '../pages/historial/historial.component';

export const AppRoute: Routes = [
  {path: '', redirectTo: 'project-management', pathMatch: 'full'},
  {path: '',
    component: FullComponent,
    children: [
      {
        path: 'project-management', loadChildren: 'src/pages/project-management/project-management.module#ProjectManagementModule',
        canActivate: [AuthGuardService],
      },
      {
        path: 'user-management', loadChildren: 'src/pages/user-management/user-management.module#UserManagementModule',
        canActivate: [AuthGuardService],
      },
      {
        path: 'user-historial', component: HistorialComponent,
        data: {
          title: 'Historial',
          urls: [
            {title: 'Hogar', url: '/project-management', target: 'project'},
            {title: 'Historial'}
          ]
        }, canActivate: [AuthGuardService],
      }
    ]
  },
  {path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication', loadChildren: 'src/authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/authentication/404'
  }
];
