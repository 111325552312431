import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MeService } from 'src/service/me.service';
import { User } from 'src/model/user';
import { AuthService } from 'src/service/auth.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare var $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() toggle = new EventEmitter<void> ();
  @Output() back = new EventEmitter<string> ();

  public config: PerfectScrollbarConfigInterface = {};
  isOpened = false;
  iam: User = new User();
  newMessage = false;
  notifications: any;

  constructor(
    private me: MeService,
    private auth: AuthService,
    private router: Router,
  ) {
    this.iam = this.me.user;
  }

  checkedNotification() {
    this.newMessage = false;
  }

  toggleSidebar() {
    this.toggle.emit();
  }

  logOut() {
    this.auth.logout();
    this.router.navigate(['/authentication/login']);
  }

  goToAccount() {
    this.back.emit('setting');
    this.router.navigate(['/account-setting']);
  }

  ngOnInit() { }
}
