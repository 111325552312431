import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppRoute } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from '../shared/header/header.component';
import { SideNavComponent } from '../shared/side-nav/side-nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatSidenavModule,
  MatInputModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTableModule,
  MatPaginatorModule,
  MatMenuModule,
  MatDialogModule,
  MatStepperModule,
  MatIconModule,
  MatButtonToggleModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatExpansionModule,
} from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { SpinnerComponent } from '../shared/spinner.component';
import { FullComponent } from '../layout/full/full.component';
import { BlankComponent } from '../layout/blank/blank.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from 'src/api/api.service';
import { RequestService } from 'src/api/request.service';
import { AuthGuardService } from 'src/service/auth-guard.service';
import { IamService } from 'src/service/iam.service';
import { MeService } from 'src/service/me.service';
import { AuthService } from 'src/service/auth.service';
import { HttpModule } from '@angular/http';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { APIProvider } from 'src/provider/api';
import { MatTabsModule} from '@angular/material/tabs';
import { HttpClientModule} from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { InternationalPhoneModule } from 'ng4-intl-phone';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { ChartistModule } from 'ng-chartist';

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/database';
import { CropDlgComponent } from '../components/dialog/crop-dlg/crop-dlg.component';
import { AlertDlgComponent } from '../components/dialog/alert-dlg/alert-dlg.component';
import { EditProjectComponent } from '../pages/project-management/edit-project/edit-project.component';
import { AssignDlgComponent } from '../components/dialog/assign-dlg/assign-dlg.component';
import { UserProjectComponent } from '../components/dialog/user-project/user-project.component';
import { HistorialComponent } from '../pages/historial/historial.component';
import { ProgDlgComponent } from '../components/dialog/prog-dlg/prog-dlg.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    HeaderComponent,
    SideNavComponent,
    BreadcrumbComponent,
    FullComponent,
    BlankComponent,
    CropDlgComponent,
    AlertDlgComponent,
    AssignDlgComponent,
    UserProjectComponent,
    HistorialComponent,
    ProgDlgComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    NgbModule,
    ChartsModule,
    ChartistModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoute),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    AngularFireStorageModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSidenavModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatMenuModule,
    MatDialogModule,
    MatButtonToggleModule,
    FontAwesomeModule,
    PerfectScrollbarModule,
    MatSelectCountryModule,
    MatTabsModule,
    MatStepperModule,
    MatIconModule,
    MatRadioModule,
    ImageCropperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    UiSwitchModule,
    InternationalPhoneModule,
    MatInputModule
  ],
  providers: [
    ApiService,
    RequestService,
    AuthGuardService,
    IamService,
    MeService,
    AuthService,
    APIProvider,
    AngularFireDatabase,
    CropDlgComponent,
    AngularFirestore,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AngularFireAuth
  ],
  entryComponents: [
    CropDlgComponent,
    AlertDlgComponent,
    AssignDlgComponent,
    UserProjectComponent,
    ProgDlgComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
