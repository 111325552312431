import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { AuthData } from '../app/auth-data';
import { User } from 'src/model/user';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(
    private request: RequestService
  ) { }
}
