import { Component, Inject, OnInit } from '@angular/core';
import { Program } from 'src/model/program';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface AssignData {
  type: string;
  program: any;
  lista: any[];
  newAdd: boolean;
}

@Component({
  selector: 'app-prog-dlg',
  templateUrl: './prog-dlg.component.html',
  styleUrls: ['./prog-dlg.component.scss']
})
export class ProgDlgComponent implements OnInit {

  newProman: Program;
  isEdit:number=0;
  newAdd: boolean;
  focused = false;
  errMsg = '';
  loading = false;
  type;
  programs: any[] = [];
  FechaEnd:string='';
  uidPadre:string = 'null';

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<AssignData>,
    @Inject(MAT_DIALOG_DATA) public data: AssignData
  )
  {
    this.programs = [];
    this.type = this.data.type;
    this.programs = this.data.lista;

    if(this.type==='edit'){
      this.newProman = this.data.program;
      this.uidPadre = this.newProman.uidPadre;
      this.isEdit=2;
    }
    else {
      this.newAdd = this.data.newAdd;
      this.newProman= new Program();
      this.newProman.percent_r = 0;
      if(!this.newAdd){
        this.isEdit=1;
        let fechita = this.programs[this.programs.length-2].end_date
        this.newProman.init_date = moment(Number(fechita)+86400000).valueOf().toString();

      }
    }
  }

  ngOnInit() { }
  leave() { this.focused = false; }
  focus() { this.focused = true; }

  submit() {
    if (!this.newProman.fase ) {
        this.errMsg = 'Ingrese nombre del programa!';
    } else if (!this.newProman.init_date ) {
      this.errMsg = 'Ingrese Fecha de inicio!';
    } else {
      this.loading = true;
      this.newProman.uidPadre = this.uidPadre;

      if(this.isEdit === 2){
        if(this.FechaEnd !== '') {
          this.newProman.end_date = moment(this.FechaEnd).valueOf().toString();

          if(this.newProman.init_date.localeCompare(this.newProman.end_date) !== 1){
            this.dialogRef.close({program: this.newProman});
          }else{
            this.errMsg = 'La fecha final tiene que ser mayor a la fecha inicial';
          }
        }
        else{ this.errMsg = 'Ingrese fecha final!'; }
      }
      else if(this.isEdit === 1){
        if(this.FechaEnd !== '') {
          this.newProman.end_date = moment(this.FechaEnd).valueOf().toString();

          if(this.newProman.init_date.localeCompare(this.newProman.end_date) !== 1){
            this.dialogRef.close({program: this.newProman});
          }else{
            this.errMsg = 'La fecha final tiene que ser mayor a la fecha inicial';
          }

        }
        else{ this.errMsg = 'Ingrese fecha final!'; }
      }
      else{
        if(!this.newProman.end_date) {
          this.errMsg = 'Ingrese fecha final!';

        }
        else{
          this.newProman.init_date = moment(this.newProman.init_date).valueOf().toString();
          this.newProman.end_date = moment(this.newProman.end_date).valueOf().toString();

          if(this.newProman.init_date.localeCompare(this.newProman.end_date) !== 1){
            this.dialogRef.close({program: this.newProman});
          }else{
            this.errMsg = 'La fecha final tiene que ser mayor a la fecha inicial';
          }

        }
      }


    }
  }

}
