import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { RequestParams } from './request-params';
import { MeService } from '../service/me.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { APIProvider } from 'src/provider/api';


@Injectable({
  providedIn: 'root'
})
export class RequestService {
  private headers = new Headers({'Content-Type': 'application/json'});

  constructor(
    private http: Http,
    private me: MeService,
    private apiProvider: APIProvider
  ) { }

  observableFact(requestParams: RequestParams): Observable<Response> {
    const options: any = {};
    if (requestParams.requestAuth) {
      requestParams.params({ token: this.me.token, user: this.me.public_identifier });
    }

    options.params = requestParams.requestParams;
    let httpRequest;
    if (requestParams.requestMethod === 'GET') {
      httpRequest = this.http.get(requestParams.requestUrl, options);
    } else if (requestParams.requestMethod === 'POST') {
      if (requestParams.requestFile) {
        httpRequest = this.http.post(
          requestParams.requestUrl,
          requestParams.requestFile,
          options
        );
      } else {
        options.headers = this.headers;
        httpRequest = this.http.post(
          requestParams.requestUrl,
          JSON.stringify(requestParams.requestPayload),
          options
        );
      }
    } else if (requestParams.requestMethod === 'PUT') {
      options.headers = this.headers;
      httpRequest = this.http.put(
        requestParams.requestUrl,
        JSON.stringify(requestParams.requestPayload),
        options
      );
    } else if (requestParams.requestMethod === 'DELETE') {
      options.headers = this.headers;
      httpRequest = this.http.delete(requestParams.requestUrl, options);
    }

    httpRequest = httpRequest
      .map(response => response.json())
      .catch(error => {
        throw error;
      });
    return httpRequest;
  }

  get(): RequestParams {
    return new RequestParams(p => this.observableFact(p)).get();
  }

  post(): RequestParams {
    return new RequestParams(p => this.observableFact(p)).post();
  }

  put(): RequestParams {
    return new RequestParams(p => this.observableFact(p)).put();
  }

  delete(): RequestParams {
    return new RequestParams(p => this.observableFact(p)).delete();
  }
}
