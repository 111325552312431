export class Program {
  key:string;
  fase: string;
  init_date: string;
  end_date: string;
  percent_t: number;
  percent_r: number;
  editable: boolean;
  uidPadre: string;
}
