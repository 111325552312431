import { Injectable } from '@angular/core';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class MeService {

  user: User = null;
  token?: string = null;
  public_identifier = '';
  email?: string = null;
  password?: string = null;

  constructor() { }

  setToken(token: string): void {
    this.token = token;
  }

  setID(id: string): void {
    this.public_identifier = id;
  }

  setAuth(email: any, pass: any): void {
    this.email = email;
    this.password = pass;
  }

  setUser(user: User ): User {
    this.user = user;
    return user;
  }

  forget(): void {
    this.user = null;
    this.token = null;
    this.public_identifier = null;
  }
}
