import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {
  failedUrl?: string = null;
  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  consumeFailedUrl(): string {
    const url = this.failedUrl;
    this.failedUrl = null;
    return url;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.auth.isAuthenticated()) {
      return Promise.resolve(true);
    }

    this.failedUrl = state.url;
    this.router.navigate(['/authentication/login']);
    return Promise.resolve(false);
  }
}
