import { Component, OnInit, ViewChild } from '@angular/core';
import { MeService } from 'src/service/me.service';
import { User } from 'src/model/user';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {
  iam: User = new User();
  Users: User[] = [];
  DataOficial: any[]=[];
  Historiales: any[] = [];
  loading = false;
  selected = false;
  userTable = { display: 'none' };
  showActionBar = true;
  displayedColumns: string[] = ['no', 'name', 'description', 'date'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  applyFilter(filterValue: string) { this.dataSource.filter = filterValue.trim().toLowerCase(); }

  constructor( private me: MeService, public db: AngularFireDatabase ) { this.iam = this.me.user; }

  ngOnInit() {
    this.getAllUsers();
    //this.getAllHistorial();
  }

  getAllHistorial(){
    this.loading = true;
    this.db.list('/historial').valueChanges().subscribe((result: any) => {
      this.Historiales = result;
      if (this.Historiales.length > 0) {
        this.dataSource = new MatTableDataSource(this.Historiales);
        this.dataSource.paginator = this.paginator;
        this.userTable = { display: 'block' };
      }
      else {
        this.userTable = { display: 'none' };
      }
      this.loading = false;
    });
  }

   getAllUsers() {
     this.loading = true;
     this.db.list('/users').valueChanges().subscribe((result: any) => {
      this.Users = result;
      if(this.Users.length > 0){
        this.db.list('/historial',order => order.orderByChild('fecha')).valueChanges().subscribe((result: any) => {
          this.Historiales = result;
          if (this.Historiales.length > 0) {
            this.Historiales.forEach(hist => {
              let nombre = this.Users.filter(item =>  hist.uidUser === item.uid)
              console.log(nombre)
              let newItem = {
                nombre: nombre[0].name,
                mensaje: hist.mensaje,
                fecha: hist.fecha
              }
              this.DataOficial.push(newItem);
            });

            const reversed = this.DataOficial.reverse();

            this.dataSource = new MatTableDataSource( reversed );
            this.dataSource.paginator = this.paginator;
            this.userTable = { display: 'block' };
          }
        });
      }
      else{
        this.userTable = { display: 'none' };
      }
      this.loading = false;
     });
   }

   isAllSelected() {
     const numSelected = this.selection.selected.length;
     const numRows = this.dataSource.data.length;
     return numSelected === numRows;
   }

   masterToggle() {
     this.isAllSelected() ?
         this.selection.clear() :
         this.dataSource.data.forEach(row => this.selection.select(row));
   }

   actionTool() {
     this.showActionBar = !this.showActionBar;
   }


}
