import { Component, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})

export class FullComponent implements OnInit {

  @Output() changedMenu = new EventEmitter<string>();
  public innerWidth: any;
  public config: PerfectScrollbarConfigInterface = {};
  showMinisidebar = false;
  target: any;
  constructor(
    public router: Router
  ) { }

  ngOnInit() {
    if (this.router.url === '/') {
      // this.router.navigate(['/authentication']);
    }
    this.handleLayout();
  }

  @HostListener('window:resize', ['$event'])

  onResize(event) {
    this.handleLayout();
  }

  toggleSidebar() {
    this.showMinisidebar = !this.showMinisidebar;
  }

  goBack(event) {
    this.target = event;
  }


  handleLayout() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.showMinisidebar = true;
    } else {
      this.showMinisidebar = false;
    }
  }

}
