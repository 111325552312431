import { Component, OnDestroy, ViewEncapsulation, Input, Inject } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-spinner',
    template: `
        <div class="preloader" *ngIf="isSpinnerVisible">
            <div class="spinner">
            </div>
        </div>`,
    encapsulation: ViewEncapsulation.None
})

export class SpinnerComponent implements OnDestroy {
    public isSpinnerVisible = true;
    @Input() public backgroundColor = '#0d46a0';

    constructor(
        private router: Router,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.isSpinnerVisible = true;
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.isSpinnerVisible = false;
            }
        },
        () => {
            this.isSpinnerVisible = false;
        });
    }

    ngOnDestroy(): void {
        this.isSpinnerVisible = false;
    }
}
