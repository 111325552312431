import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { MeService } from './me.service';
import { IamService } from './iam.service';
import { User } from '../model/user';
import { AuthData } from '../app/auth-data';
import { RequestParams } from 'src/api/request-params';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable()
export class AuthService {

  constructor(
    private api: ApiService,
    private me: MeService,
    private iAm: IamService,
    private db: AngularFireDatabase,
  ) { }

  rememberToken(token) {
    localStorage.setItem('user', token);
  }

  getToken() {
    return localStorage.getItem('user');
  }

  rememberID(id) {
    localStorage.setItem('user_id', id);
  }

  getID() {
    return localStorage.getItem('user_id');
  }

  canAutoLogin(): boolean {
    return this.getToken() !== null;
  }

  isAuthenticated(): boolean {
    return this.me.token !== null;
  }

  // login(auth: AuthData): Promise<User> {
  //   return this.api.user.login(auth).promise()
  //     .then((resp: any) => {
  //       if(resp.message === 'SMS' || resp.message === 'Email') {
  //         return resp.message;
  //       } else {
  //         this.me.setToken(resp.message.token.token);
  //         this.rememberToken(resp.message.token.token);
  //         this.rememberID(resp.message.userIdentifier);
  //         this.me.public_identifier = resp.message.userIdentifier;
  //         return 'Success'
  //       }
  //     })
  //     .then(data => {
  //       if(data !== 'Success') {
  //         return {success: false, message: data};
  //       } else {
  //         return this.getMyInfo();
  //       }
  //     });
  // }

  logout(): void {
    this.me.forget();
    // this.eventProvider.publishLogOutSync();
    localStorage.removeItem('user');
    localStorage.removeItem('user_id');
  }

  // public async getMyInfo(): Promise<any> {
  //   let response;
  //   await this.api.user.me().promise()
  //     .then((resp: any) => {
  //       console.log('me response====>', resp);
  //       // this.eventProvider.publishLoginSync();
  //       if(resp.message.role === 1) {
  //         let user = new User();
  //         user = resp.message;
  //         this.me.setUser(user);
  //         response = user;
  //       } else {
  //         this.me.setUser(resp.message);
  //         response = resp.message;
  //       }
  //     });
  //   return response;
  // }
}
