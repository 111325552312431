import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface AlertData {
  message: string;
}

@Component({
  selector: 'app-alert-dlg',
  templateUrl: './alert-dlg.component.html',
  styleUrls: ['./alert-dlg.component.scss']
})
export class AlertDlgComponent implements OnInit {

  msg = '';
  constructor(
    public dialogRef: MatDialogRef<AlertData>,
    @Inject(MAT_DIALOG_DATA) public data: AlertData
  ) {
    this.msg = this.data.message;
   }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close({confirm: true});
  }

}
