import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'master-admin';
  showLayout = true;
  constructor() {}

  ngAfterContentChecked() {
    if (window.location.href.indexOf('login') > -1) {
      this.showLayout = false;
    } else {
      this.showLayout = true;
    }
  }
  async ngOnInit() {  }

}
