import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MeService } from 'src/service/me.service';
import { User } from 'src/model/user';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  active = 'project';
  location: any;
  iam: User = new User();
  @Input()
  set changePage(changePage: any) {
    if (changePage && changePage.indexOf('project') > -1) {
      this.active = 'project';
    } else {
      this.active = changePage;
    }
  }
  constructor(
    private router: Router,
    private me: MeService
  ) {
    this.iam = this.me.user;
    this.location = window.location.href;
  }

  ngOnInit() {
    const location = this.location.split('/')[3];
    this.active = location.split('-')[0];
  }

  navigate(item) {
    this.active = item;
    switch (item) {
      case 'project':
        this.router.navigate(['/project-management']);
        break;
      case 'user':
        this.router.navigate(['/user-management']);
        break;
      case 'historial':
        this.router.navigate(['/user-historial']);
        break;
    }
  }
}
