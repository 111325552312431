import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { AngularFireDatabase } from '@angular/fire/database';
import { User } from 'src/model/user';
import * as firebase from 'firebase/app';

export interface AssignData {
  title: string;
  key: string;
  param: string;
}


@Component({
  selector: 'app-user-project',
  templateUrl: './user-project.component.html',
  styleUrls: ['./user-project.component.scss']
})
export class UserProjectComponent implements OnInit {

  type;
  title;
  table = {
    display: 'none'
  };
  projects = [];
  users = [];
  noData;
  displayedColumns;
  dataSource;
  selection;
  loading = false;
  projectKey;
  param;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    public dialogRef: MatDialogRef<AssignData>,
    @Inject(MAT_DIALOG_DATA) public data: AssignData,
    public db: AngularFireDatabase
  )
  {
    this.projectKey = this.data.key;
    this.title = 'Usuarios asignados a '+this.data.title;
    this.displayedColumns = ['select', 'photo', 'name', 'email', 'phone', 'role'];
    this.dataSource = new MatTableDataSource<User>();
    this.selection = new SelectionModel<User>(true, []);
  }

  ngOnInit() {
    this.db.list('/users').valueChanges().subscribe((result: User[]) => {
      //console.log('users ====>', result);
      this.users = result;
      if (this.users.length > 0) {
        this.noData = false;
        let UsersFilter = new Array();
        this.users.forEach(item =>  {
          let mostrar = false;
          if(item.assigned_projects){
            let arrays = Object.values(item.assigned_projects)
            arrays.forEach(proj =>{
              if(proj === this.projectKey){ mostrar = true; }
            })
            if(mostrar){UsersFilter.push(item);}
          }
        });
        this.dataSource = new MatTableDataSource(UsersFilter);
        this.dataSource.paginator = this.paginator;
        this.table = {
          display: 'block'
        };
      } else {
        this.noData = true;
        this.table = {
          display: 'none'
        };
      }
      this.loading = false;
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${this.type === 'user' ? row.project_key + 1 : row.uid + 1}`;
  }

  cancel() {
    this.dialogRef.close();
  }

  remove() {
    if (this.selection.selected.length > 0) {
      var este = this;
      Promise.all(this.selection.selected.map(user => {
        // Imprime nombres de variables y valores usando Array.forEach
        Object.getOwnPropertyNames(user.assigned_projects).forEach(function(val, idx, array) {

          if(este.projectKey === user.assigned_projects[val]){
            //console.log(val + " -> " + user.assigned_projects[val]);
            //console.log(val );
            firebase.database().ref().child('users/' + user.uid)
            .child('assigned_projects/'+val).remove((error)=>{
              if(error){
                console.log(error)
              }else {
                console.log("borre el proyecto: " + val+ " de el usuario  "+user.uid)
              }
            });
          }
        });
      }));
      this.dialogRef.close({confirm: true});
    }
  }

}
