import { Component, OnInit, Inject } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as firebase from 'firebase/app';

export interface CropData {
  changeEvent: any;
  name: string;
}

@Component({
  selector: 'app-crop-dlg',
  templateUrl: './crop-dlg.component.html',
  styleUrls: ['./crop-dlg.component.scss']
})
export class CropDlgComponent implements OnInit {
imageChangedEvent: any = '';
  croppedImage: any = '';
  uploading = false;
  fileName: string;
  uploadedImage = '';

  constructor(
    public dialogRef: MatDialogRef<CropData>,
    @Inject(MAT_DIALOG_DATA) public data: CropData,
  ) {
    this.imageChangedEvent = this.data.changeEvent;
    this.fileName = this.data.name;
   }

  ngOnInit() {
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
      // show cropper
  }

  cropperReady() {
      // cropper ready
  }

  loadImageFailed() {
      // show message
  }

  async cropImage() {
    this.uploading = true;
    const photoRef = firebase.storage().ref('/photos/');
    const imageRef = photoRef.child(this.generateUUID() + '.jpeg');
    imageRef.putString(this.croppedImage, 'data_url')
      .then(snapshot => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.dialogRef.close({uploadedUrl: downloadURL});
        });
      }, err => {
      });
  }

  generateUUID(): any {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
}
